import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SioService {
  private sioUrl: string = environment.sioUrl;
  id = '';
  socket: Socket;

  constructor() {}

  get socketId() {
    return this.id;
  }

  setupSocketConnection() {
    this.socket = io(this.sioUrl);
    this.socket.once('connect', () => {
      this.id = this.socket.id;
      console.log(this.socket.id);
    });
  }

  getMessageById() {
    return Observable.create((observer: any) => {
      this.socket.on(this.socketId, (data: any) => {
        if (data) {
          observer.next(data);
        } else {
          observer.error('Unable To Reach Server');
        }
      });
      return () => {
        this.socket.disconnect();
      };
    });
  }
}
