// Angular imports
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

// App own modules imports
import { AuthService } from '@core/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    if (!this.authService.login.status) {
      return true; // Continue
    } else {
      // Redirect to IoT if user is logged
      this.router.navigate(['/iot']);
      return false;
    }
  }
}
