// Angular imports
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

// Third-party libraries imports
import { Observable } from 'rxjs';

// Module own classes imports
import { AuthService } from '@core/services/auth.service';

// Environment import
import { environment } from '@environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  // Intercept any Http request and set Authorization header, if a token exists
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('Request intercepted');
    const isLoggedIn: boolean = this.authService.login.status;
    const token: string | null = localStorage.getItem('token');

    // If user is logged or a token is present, and it is an API request, add token to Authorization header
    if (isLoggedIn || token) {
      // Add auth header
      console.log('Injecting authorization header...');
      request = request.clone({
        setHeaders: { Authorization: `${token}` },
      });
    }

    return next.handle(request);
  }
}
