// Angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Third-party libraries imports
import { Observable, EMPTY } from 'rxjs';

// Module own classes imports
import {
  IApiGetResponse,
  IApiPostResponse,
  IApiPatchResponse,
  IApiDeleteResponse,
  ApiGetResult,
  IDownloadBody,
} from '@core/core.interfaces';

// Environment import
import { environment } from '@environments/environment';
import { timeout } from 'rxjs/operators';
import { SioService } from './sio.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private sio: SioService) {}

  get(path: string): Observable<IApiGetResponse> {
    return this.http.get<IApiGetResponse>(this.apiUrl + path);
  }

  post(path: string, body: object): Observable<IApiPostResponse> {
    return this.http.post<IApiPostResponse>(`${this.apiUrl}${path}`, body, { headers: { socketid: this.sio.socketId } });
  }

  patch(path: string, id: string, body: object): Observable<IApiPatchResponse> {
    return this.http.patch<IApiPatchResponse>(
      `${this.apiUrl}${path}/${id}`,
      body
    );
  }

  del(path: string, id: string): Observable<IApiDeleteResponse> {
    return this.http.delete<IApiDeleteResponse>(`${this.apiUrl}${path}/${id}`);
  }

  download(path: string, body?: any) {
    return this.http.post<{ data: Blob }>(this.apiUrl + path, body, {
      responseType: 'blob' as 'json',
    });
  }

  downloadCsv(body: any) {
    return this.http.post<{ data: Blob }>(this.apiUrl + 'download', body, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  upload(path: string, content: any) {
    console.log(this.sio.socketId);
    return this.http.post(this.apiUrl + path, content, { headers: { socketid: this.sio.socketId } }).pipe(timeout(600000));
  }

  request(action: string, path: string, id?: any, body?: any): Observable<any> {
    switch (action) {
      case 'list':
        return this.get(path);
      case 'read':
        return this.get(`${path}/${id}`);
      case 'delete_invoices':
      case 'GEL':
      case 'create':
        return this.post(path, body);
      case 'update':
        return this.patch(path, id, body);
      case 'delete':
        return this.del(path, id);
      default:
        return EMPTY;
    }
  }
}
