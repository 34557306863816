// Angular imports
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Module own classes imports
import { AuthService } from '@core/services/auth.service';

@Injectable({ providedIn: 'root' })
export class IotGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  // RouterStateSnapshot needs ActivatedRouteSnapshot to work even is not used
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Check if user token exists
    const user = this.authService.login.user;
    const keys = Object.keys(user);

    // Check if user is defined and contains this fields
    if (
      typeof user === 'object' &&
      keys.includes('username') &&
      keys.includes('email') &&
      keys.includes('role') &&
      keys.includes('name')
    ) {
      this.authService.login.status = true;
    }

    if (this.authService.login.status) {
      return true; // Continue
    } else {
      // Redirect to login
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url }, // In url will show returnUrl
      });
      return false;
    }
  }
}
