// Angular imports
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Third-party libraries imports
import { map, catchError } from 'rxjs/operators';

// Module own classes imports
import { ILogin } from '@core/core.interfaces';
import { ApiService } from '@core/services/api.service';
import { User } from '@features/dashboard/users/users.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginInfo: ILogin = { status: false, user: {} as User };
  userPath = 'users/';

  constructor(
    private apiService: ApiService,
    private router: Router
    ) { }

  get login(): ILogin {
    return this.loginInfo;
  }

  loginUser(username: string, password: string) {
    return this.apiService
      .post('users/authenticate', { username, password })
      .pipe(
        map(
          (data: any) => {
            if (data.success) {
              console.log('Authentication success', data);
              console.log('Storing token...');
              localStorage.setItem('token', data.result.token);
              this.loginInfo = { status: true, user: data.result.user };
            } else {
              console.log('Authentication fail', data);
              console.log('Removing token...');
              localStorage.removeItem('token');
            }
          },
          catchError((error: any) => {
            console.log('Authentication error', error);
            console.log('Removing token...');
            localStorage.removeItem('token');
            return error;
          })
        )
      );
  }

  logout() {
    // Data is removed
    localStorage.removeItem('token');
    this.login.status = false;
    this.router.navigate(['/auth/login/']).then(() => {
      // Redirect and reload
      window.location.reload();
    });
  }

  validateUser() {
    // Try to validate, any error except 500 is catched
    return this.apiService
      .get('users/validate')
      .pipe(
        map(
          (data: any) => {
            if (data.success) {
              console.log('Validation success', data);
              console.log('Storing token...');
              localStorage.setItem('token', data.result.token);
              this.loginInfo = { status: true, user: data.result.user };
            } else {
              console.log('Validation fail', data);
              console.log('Removing token...');
              localStorage.removeItem('token');
            }
            return data;
          },
          catchError((error: any) => {
            console.log('Validation error', error);
            console.log('Removing token...');
            localStorage.removeItem('token');
            return error;
          })
        )
      );
  }
}
