import { User } from '@features/dashboard/users/users.interfaces';

export interface ApiGetUsers {
  success: boolean;
  result: User[];
}

export interface ApiGetUserDetails {
  success: boolean;
  result: User;
}

export interface IGrafanaFolder {
  id: number;
  title: string;
  dashboards: IGrafanaIframeAttrs[];
}

export interface IGrafanaIframeAttrs {
  src: string;
  title: string;
  id: string;
}

export interface ApiGetResult {
  success: boolean;
  result: any;
}

export interface IDownloadBody {
  format: string;
  attrs: string[];
  startDate: string;
  endDate: string;
  limit: number;
}

export interface ILogin {
  status: boolean;
  user: User;
}

export interface IApiGetResponse {
  success: boolean;
  result: any;
}

export interface IApiPostResponse {
  success: boolean;
  message: string;
  result: any;
}

export interface IApiPatchResponse {
  success: boolean;
  message: string;
  result: any;
}

export interface IApiDeleteResponse {
  success: boolean;
  message: string;
}
