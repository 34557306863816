// Angular imports
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

// Module own classes imports
import { AuthService } from '@core/services/auth.service';
import { User } from '@features/dashboard/users/users.interfaces';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  logged = true;
  user: User;

  constructor(
    private auth: AuthService,
    private router: Router,
    private title: Title
  ) {}

  ngOnInit(): void {
    console.log('Initializing app...');
    // If URL base path does not match those from app-routing.module.ts, redirect as per login status
    if (!['auth', 'iot'].includes(window.location.pathname.split('/')[1])) {
      if (this.auth.login.status) {
        console.log('Redirecting to dashboard...');
        this.router.navigate(['/iot']);
      } else {
        console.log('Redirecting to login...');
        this.router.navigate(['/auth']);
      }
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe(() => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root)[0];
        this.title.setTitle(`Elliot Energy - ${title}`);
      });
  }

  getTitle(state: any, parent: any): any {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
