// Third-party libraries imports
import { JwtHelperService } from '@auth0/angular-jwt';

// App own modules imports
import { AuthService } from '@core/services/auth.service';

export function appInitializer(authService: AuthService) {

  return () =>
    new Promise((resolve: any) => {
      const jwtHelper = new JwtHelperService();
      // If there is a token stored and it is not expired, send validation request
      if (localStorage.getItem('token') && !jwtHelper.isTokenExpired(`${localStorage.getItem('token')}`)) {
        authService.validateUser().subscribe().add(resolve);
      } else {
        // Otherwise, remove token and return an empty observable
        localStorage.removeItem('token');
        resolve();
      }
    });
}
